
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































.management,
[class*='management--'] {
}

.management__contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
}

.management__contact__title {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  color: $elantis-blue;

  h3 {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1;
  }

  span {
    font-size: 1.4rem;
  }
}

.management__contact__key {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  ::v-deep svg {
    width: 2rem;
  }
}

.management__contact__link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}
